import React, { useRef, useState } from "react";
import { Colors, Constants, Fonts, Images } from "../../../../config";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native-web";
import { NextSeo } from "next-seo";
import { useTranslation } from "react-i18next";
import { CImage, IconizedButton } from "../../../../components/atoms";
import ProfileModalContainer from "../../modals/ProfileModalContainer";
import { useRouter } from "next/router";
import ProfileBottomMenuBar from "../../components/ProfileBottomMenuBar";
import { generateURLSlug, getSlugFromName } from "../../../../../helpers";
import Head from "next/head";
import {
  capitalizeFirstLetter,
  getClass,
} from "../../../../helpers/formatHelpers";
import dynamic from "next/dynamic";
import Link from "next/link";

const PopupMenuListModal = dynamic(
  () => import("../../../../components/molecules/PopupMenuListModal"),
  {
    ssr: false,
  }
);

const VenueInfoView = dynamic(() => import("../components/VenueInfoView"), {
  ssr: true,
});

const ProfileHeader = dynamic(() => import("../../components/ProfileHeader"), {
  ssr: false,
});

const ProfileBottomView = dynamic(
  () => import("../../components/ProfileBottomView"),
  {
    ssr: true,
  }
);

const VenueProfileView = (props) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const navigation = useRouter();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const { tabType, subTabType, venueEvents, category, isMobileVersion } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const [isShowMenuMagic, setIsShowMenuMagic] = useState(false);
  const itemsOther = isMobileVersion
    ? [
        {
          id: 12,
          title: t("key_report_mistake"),
          icon: Images.iconEdit,
          type: Constants.profileMenuType.PROFILE_MENU_REPORT_MISTAKE,
        },
        {
          id: 3,
          title: t("key_id_profile"),
          icon: Images.iconBarcode,
          type: Constants.profileMenuType.PROFILE_MENU_ID_PROFILE,
        },
        {
          id: 13,
          title: t("key_modal_title_share"),
          icon: Images.iconShareColored,
          type: Constants.profileMenuType.PROFILE_MENU_SHARE,
        },
      ]
    : [
        {
          id: 12,
          title: t("key_report_mistake"),
          icon: Images.iconEdit,
          type: Constants.profileMenuType.PROFILE_MENU_REPORT_MISTAKE,
        },
        {
          id: 3,
          title: t("key_id_profile"),
          icon: Images.iconBarcode,
          type: Constants.profileMenuType.PROFILE_MENU_ID_PROFILE,
        },
      ];
  const itemsMagic = [
    {
      id: 11,
      title: t("key_booking"),
      icon: Images.iconBooking,
      type: Constants.profileMenuType.PROFILE_MENU_BOOKING,
    },
    // {
    //   id: 7,
    //   title: t("key_insights"),
    //   icon: Images.iconInsight,
    //   type: Constants.profileMenuType.PROFILE_MENU_INSIGHTS,
    // },
  ];
  const onPressMenuItem = (item) => {
    if (item.type == Constants.profileMenuType.PROFILE_MENU_EDITION) {
      navigation.push(
        `${generateURLSlug(
          props?.venue?.id,
          props?.venue?.title,
          props?.venue?.startDate,
          category,
          "edition"
        )}`
      );
    }
    if (item.type == Constants.profileMenuType.PROFILE_MENU_ID_PROFILE) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ID_PROFILE
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_REPORT) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_REPORT,
        {
          reportCategory: Constants.reportCategory.REPORT_FESTIVAL,
        }
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_UPDATE) {
      navigation.navigate("VenueUpdateScreen", { venueId: props.venue.id });
    } else if (
      item.type == Constants.profileMenuType.PROFILE_MENU_CONTACT_HOST
    ) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_CONTACT
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_CALENDAR) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_CALENDAR
      );
    } else if (
      item.type == Constants.profileMenuType.PROFILE_MENU_TICKET_LINK
    ) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ADD_TICKET_LINK
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_CALENDAR) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ADD_TO_CALENDAR
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_INSIGHTS) {
      navigation.push({
        pathname: "/profile/insight",
        query: {
          id: props.venue.id,
          profileId: props.venue.profileId,
        },
      });
    } else if (
      item.type == Constants.profileMenuType.PROFILE_MENU_REPORT_MISTAKE
    ) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_REPORT_MISTAKE
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_SHARE) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_SHARE
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_BOOKING) {
      setIsShowMenuMagic(false);
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_BOOKING
      );
    }
  };

  const onChangeIsShown = (flag) => {
    setIsShowMenu(flag);
  };
  let photos = props?.venue?.covers?.map((i) => ({ url: i.image }));
  if (photos && !Array.isArray(photos)) {
    photos = JSON.parse(photos);
  }
  const backgroundImage =
    photos?.length > 0 ? photos[activeIndex].url : "Local:imagePlaceholder";

  var item = props?.venue;
  const id = item?.id;
  const nickname = item?.nickname;
  const slug = getSlugFromName(nickname, null);
  const canonicalURL = `https://${
    process.env.NEXT_PUBLIC_WWW_BASE_URL
  }${generateURLSlug(
    props?.venue?.id,
    props?.venue?.nickname,
    null,
    category,
    "about"
  )}`;
  const metaTitle = `${props?.venue?.nickname}${
    tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
  } - ${process.env.NEXT_PUBLIC_APP_NAME}`;
  const metaDescription = `Discover${
    category === Constants.categoryType.CATEGORY_ANIMAL ? " the" : ""
  } ${props?.venue?.nickname} ${
    tabType === "media"
      ? `media gallery on ${process.env.NEXT_PUBLIC_APP_NAME}.`
      : tabType === "wall"
      ? `community on ${process.env.NEXT_PUBLIC_APP_NAME} - posts about info, news and much more.`
      : tabType === "memories"
      ? `reviews on ${process.env.NEXT_PUBLIC_APP_NAME}.`
      : `profile on ${process.env.NEXT_PUBLIC_APP_NAME} - ${
          process.env.NEXT_PUBLIC_APP_NAME === "Vybeful" ||
          process.env.NEXT_PUBLIC_APP_NAME === "Festyful"
            ? "music, location"
            : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
            ? "price, features"
            : category === Constants.categoryType.CATEGORY_PREFAB_COMPANY
            ? "homes, features"
            : category === Constants.categoryType.CATEGORY_ANIMAL
            ? "habitat, features"
            : "location, features"
        } and much more.` //else about
  }`;

  return (
    <div
      className={`${getClass("background", true)} mb-0 flex-1 flex flex-col`}>
      <Head>
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": canonicalURL,
              url: canonicalURL,
              name: props?.venue?.nickname,
              datePublished: props?.venue?.createdAt,
              dateModified: props?.venue?.updatedAt,
              image: [props?.venue?.logo || props?.venue?.avatar]
                .filter((i) => i !== null)
                .map((i) => ({ "@id": i })),
              description: props?.venue?.description?.replace(
                /(<([^>]+)>)/gi,
                ""
              ),
            }),
          }}
        />
      </Head>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        canonical={canonicalURL}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: canonicalURL,
          title: metaTitle,
          description: metaDescription,
          images: [
            {
              url: props?.venue?.logo
                ? props?.venue?.logo
                : props?.venue?.avatar
                ? props?.venue?.avatar
                : `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}/images/${
                    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                          " ",
                          ""
                        )?.toLowerCase() + "/"
                      : ""
                  }image_placeholder_new.png`,
              width: 1200,
              height: 630,
              alt: `${props?.venue?.nickname}${
                tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
              } - ${process.env.NEXT_PUBLIC_APP_NAME}`,
              type: "image/png",
            },
          ],
          siteName: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
        }}
      />
      <div
        className={`${
          isMobileVersion ? "" : "flex flex-col h-[650px] self-stretch"
        } relative`}>
        <CImage
          priority={true}
          objectFit={"fill"}
          imgUrl={backgroundImage}
          quality={1}
          alt={`${props?.venue?.nickname} ${
            category === Constants.categoryType.CATEGORY_ARTIST
              ? "Artist"
              : category === Constants.categoryType.CATEGORY_VENUE
              ? "Venue"
              : category === Constants.categoryType.CATEGORY_ROOFTOP
              ? "Rooftop"
              : category === Constants.categoryType.CATEGORY_SPA
              ? "Spa"
              : category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL
              ? "Beach Club"
              : category === Constants.categoryType.CATEGORY_PREFAB_COMPANY
              ? "Company"
              : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
              ? "Home"
              : category === Constants.categoryType.CATEGORY_ZOO
              ? "Zoos"
              : category === Constants.categoryType.CATEGORY_ANIMAL
              ? "Animals"
              : category === Constants.categoryType.CATEGORY_PARKFUL
              ? "Parks"
              : category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL
              ? "Bar"
              : category === Constants.categoryType.CATEGORY_EVENT
              ? "Event"
              : "Festival"
          }`}
        />
        <div
          className="bg-white/2 absolute bottom-0 left-0 right-0 top-0"
          style={{ backdropFilter: "blur(40px)" }}
        />

        <ProfileHeader
          photos={
            props?.venue?.covers && props?.venue?.covers?.length > 0
              ? props?.venue?.covers?.map((i) => ({ url: i.image })) || []
              : [{ url: "Local:imagePlaceholder" }]
          }
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          items={itemsOther}
          venue={props.venue}
          isShowMenu={isShowMenu}
          onChangeIsShown={onChangeIsShown}
          onPressMenuItem={onPressMenuItem}
          style={{
            maxWidth: Constants.PROFILE_PAGE_WIDTH,
            width: "100%",
            height: isMobileVersion ? 239 : 530,
            marginBottom: 120,
            alignSelf: "center",
          }}
          isMobileVersion={isMobileVersion}
          category={category}
        />
      </div>

      <VenueInfoView
        style={{
          borderTopLeftRadius: isMobileVersion ? 25 : 120,
          borderTopRightRadius: isMobileVersion ? 25 : 120,
          marginTop: -120,
        }}
        item={props.venue}
        category={category}
        isMobileVersion={isMobileVersion}
      />

      <ProfileBottomView
        style={{
          marginTop: isMobileVersion ? 0 : 20,
          maxWidth: Constants.PROFILE_PAGE_WIDTH,
          width: "100%",
          alignSelf: "center",
        }}
        item={props.venue}
        category={category}
        isNested
        initialTabType={tabType}
        subTabType={subTabType}
        venueEvents={venueEvents}
        isMobileVersion={isMobileVersion}
      />

      <ProfileModalContainer
        ref={modalRef}
        item={props.venue}
        category={category}
        isMobileVersion={isMobileVersion}
      />

      <ProfileBottomMenuBar
        profile={props.venue}
        isMobileVersion={isMobileVersion}
        category={category}
        hideContact={true}
        // hideContact={
        //   category != Constants.categoryType.CATEGORY_ZOO &&
        //   category != Constants.categoryType.CATEGORY_PARKFUL
        // }
        hideShare={isMobileVersion}
        onMorePress={() => {
          setIsShowMenu(true);
        }}
        onContactPress={() =>
          modalRef.current.showModal(
            Constants.profileModalType.PROFILE_MODAL_CONTACT
          )
        }
        onSharePress={() =>
          modalRef.current.showModal(
            Constants.profileModalType.PROFILE_MODAL_SHARE
          )
        }
        renderMagicButton={() => {
          return (
            // <TouchableOpacity onPress={() => setIsShowMenuMagic(true)}>
            //   <CImage
            //     source={Images.iconMenuMagic}
            //     width={60}
            //     height={60}
            //     layout="fixed"
            //   />
            // </TouchableOpacity>
            null
          );
        }}
        renderButton={() => {
          if (category === Constants.categoryType.CATEGORY_ANIMAL) {
            return <div>&nbsp;</div>;
            // } else if (
            //   category === Constants.categoryType.CATEGORY_ZOO ||
            //   category === Constants.categoryType.CATEGORY_PARKFUL
            // ) {
          } else if (
            (category === Constants.categoryType.CATEGORY_APARTHOTEL ||
              category === Constants.categoryType.CATEGORY_RESORT) &&
            props.venue.isBookingButton
          ) {
            return isMobileVersion ? (
              <Link
                href={props.venue.bookWebsite || props.venue.contact.website}>
                <a
                  target="_blank"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                  }}>
                  <IconizedButton
                    isHeading
                    buttonTitle="Booking"
                    icon={Images.iconBooking}
                    onPress={() => null}
                    filled
                    fontSize={isMobileVersion ? 12 : 16}
                    iconStyle={
                      isMobileVersion
                        ? { width: 16, height: 16 }
                        : { width: 20, height: 20 }
                    }
                    style={
                      isMobileVersion
                        ? { height: 32, flex: 1 }
                        : { height: 40, width: 320 }
                    }
                    isMobileVersion={isMobileVersion}
                  />
                </a>
              </Link>
            ) : (
              <Link
                href={props.venue.bookWebsite || props.venue.contact.website}>
                <a
                  target="_blank"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <View
                    style={[
                      styles.contactButton,
                      isMobileVersion
                        ? { marginRight: 8, height: 34, width: "auto" }
                        : {},
                    ]}>
                    <CImage
                      width={isMobileVersion ? 16 : 20}
                      height={isMobileVersion ? 16 : 20}
                      layout="fixed"
                      objectFit={"cover"}
                      source={Images.iconBooking}
                      alt="contact"
                    />
                    <Text
                      style={[
                        styles.contactButtonText,
                        category === Constants.categoryType.CATEGORY_PARKFUL ||
                        category ===
                          Constants.categoryType.CATEGORY_APARTHOTEL ||
                        category === Constants.categoryType.CATEGORY_RESORT ||
                        category ===
                          Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                          ? { color: Colors.black }
                          : {},
                        isMobileVersion ? { fontSize: 12 } : {},
                        { textTransform: "uppercase" },
                      ]}>
                      <h2>Booking</h2>
                    </Text>
                  </View>
                </a>
              </Link>
            );
          } else if (
            category === Constants.categoryType.CATEGORY_PREFAB_HOMES
          ) {
            return isMobileVersion ? (
              <Link
                href={generateURLSlug(
                  props?.venue?.company?.id,
                  props?.venue?.company?.nickname,
                  null,
                  Constants.categoryType.CATEGORY_PREFAB_COMPANY,
                  "about"
                )}>
                <a className="w-full flex flex-1">
                  <IconizedButton
                    isHeading
                    buttonTitle="Company Profile"
                    onPress={() => {}}
                    filled
                    fontSize={isMobileVersion ? 12 : 16}
                    iconStyle={
                      isMobileVersion
                        ? { width: 16, height: 16 }
                        : { width: 20, height: 20 }
                    }
                    style={
                      isMobileVersion
                        ? { height: 32, flex: 1 }
                        : { height: 40, width: 320 }
                    }
                    isMobileVersion={isMobileVersion}
                  />
                </a>
              </Link>
            ) : (
              <Link
                href={generateURLSlug(
                  props?.venue?.company?.id,
                  props?.venue?.company?.nickname,
                  null,
                  Constants.categoryType.CATEGORY_PREFAB_COMPANY,
                  "about"
                )}>
                <a>
                  <TouchableOpacity
                    onPress={() => {}}
                    style={[
                      styles.contactButton,
                      isMobileVersion
                        ? { marginRight: 8, height: 34, width: "auto" }
                        : {},
                    ]}>
                    <Text
                      style={[
                        styles.contactButtonText,
                        isMobileVersion ? { fontSize: 12 } : {},
                        { textTransform: "uppercase" },
                        { color: Colors.white },
                      ]}>
                      <h2>Company Profile</h2>
                    </Text>
                  </TouchableOpacity>
                </a>
              </Link>
            );
          } else {
            return isMobileVersion ? (
              <IconizedButton
                isHeading
                buttonTitle="Contact"
                icon={Images.iconChatColor}
                onPress={() =>
                  modalRef.current.showModal(
                    Constants.profileModalType.PROFILE_MODAL_CONTACT
                  )
                }
                filled
                fontSize={isMobileVersion ? 12 : 16}
                iconStyle={
                  isMobileVersion
                    ? { width: 16, height: 16 }
                    : { width: 20, height: 20 }
                }
                style={
                  isMobileVersion
                    ? { height: 32, flex: 1 }
                    : { height: 40, width: 320 }
                }
                isMobileVersion={isMobileVersion}
              />
            ) : (
              <TouchableOpacity
                onPress={() => {
                  modalRef.current.showModal(
                    Constants.profileModalType.PROFILE_MODAL_CONTACT
                  );
                }}
                style={[
                  styles.contactButton,
                  isMobileVersion
                    ? { marginRight: 8, height: 34, width: "auto" }
                    : {},
                ]}>
                <CImage
                  width={isMobileVersion ? 16 : 20}
                  height={isMobileVersion ? 16 : 20}
                  layout="fixed"
                  objectFit={"cover"}
                  source={
                    category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                      ? Images.iconChatColor
                      : Images.iconChat
                  }
                  alt="contact"
                />
                <Text
                  style={[
                    styles.contactButtonText,
                    isMobileVersion ? { fontSize: 12 } : {},
                    { textTransform: "uppercase" },
                    category === Constants.categoryType.CATEGORY_ZOO ||
                    category === Constants.categoryType.CATEGORY_PARKFUL ||
                    category === Constants.categoryType.CATEGORY_CASINO ||
                    category === Constants.categoryType.CATEGORY_RESORT ||
                    category === Constants.categoryType.CATEGORY_ROOFTOP ||
                    category === Constants.categoryType.CATEGORY_APARTHOTEL ||
                    category ===
                      Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
                    category === Constants.categoryType.CATEGORY_SPA ||
                    category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                      ? { color: Colors.black }
                      : category ===
                          Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
                        category === Constants.categoryType.CATEGORY_CASTLE
                      ? { color: Colors.white }
                      : {},
                  ]}>
                  <h2>Contact</h2>
                </Text>
              </TouchableOpacity>
            );
          }
        }}
      />

      {/* not ready to publish */}
      {/* {isMobileVersion ? (
        <View
          style={[
            {
              height: isMobileVersion ? 62 : 80,
              marginTop: -30,
              justifyContent: "space-around",
              flexDirection: "row",
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
              alignSelf: "center",
              paddingHorizontal: 30,
              borderTopLeftRadius: isMobileVersion ? 25 : 40,
              borderTopRightRadius: isMobileVersion ? 25 : 40,
              alignItems: "center",
              backgroundColor: Colors.grayBackground,
              filter: "drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.5))",
              position: "fixed",
              bottom: 0,
            },
          ]}>
          <TouchableOpacity
            onPress={() =>
              modalRef.current.showModal(
                Constants.profileModalType.PROFILE_MODAL_CALENDAR
              )
            }>
            <CImage
              source={Images.iconCalendarWhite}
              width={23}
              height={23}
              layout="fixed"
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setIsShowMenuMagic(true)}>
            <CImage
              source={Images.iconMenuMagic}
              width={50}
              height={50}
              layout="fixed"
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              modalRef.current.showModal(
                Constants.profileModalType.PROFILE_MODAL_CONTACT
              )
            }>
            <CImage
              source={Images.iconChat}
              width={23}
              height={23}
              layout="fixed"
            />
          </TouchableOpacity>
        </View>
      ) : (
        <ProfileBottomMenuBar
          profile={props.venue}
          isMobileVersion={isMobileVersion}
          category={category}
          hideShare={isMobileVersion}
          onMorePress={() => {
            setIsShowMenu(true);
          }}
          onContactPress={() =>
            modalRef.current.showModal(
              Constants.profileModalType.PROFILE_MODAL_CONTACT
            )
          }
          onSharePress={() =>
            modalRef.current.showModal(
              Constants.profileModalType.PROFILE_MODAL_SHARE
            )
          }
          renderMagicButton={() => {
            return (
              <TouchableOpacity onPress={() => setIsShowMenuMagic(true)}>
                <CImage
                  source={Images.iconMenuMagic}
                  width={60}
                  height={60}
                  layout="fixed"
                />
              </TouchableOpacity>
            );
          }}
          renderButton={() => {
            return (
              <View>
                <IconizedButton
                  isHeading
                  buttonTitle={t("key_button_calendar")}
                  icon={Images.iconCalendar}
                  onPress={() =>
                    modalRef.current.showModal(
                      Constants.profileModalType.PROFILE_MODAL_CALENDAR
                    )
                  }
                  filled
                  fontSize={isMobileVersion ? 12 : 16}
                  iconStyle={{ width: 20, height: 20 }}
                  style={
                    isMobileVersion
                      ? { height: 32, width: "100%" }
                      : { height: 40, width: 320 }
                  }
                  isMobileVersion={isMobileVersion}
                />
              </View>
            );
          }}
        />
      )} */}
      {isShowMenuMagic && (
        <PopupMenuListModal
          isVisible={isShowMenuMagic}
          onClose={() => {
            setIsShowMenuMagic(false);
          }}
          onPressItem={onPressMenuItem}
          items={itemsMagic}
          isMobileVersion={isMobileVersion}
          category={props.category}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  bottomContainer: {
    height: 90,
    alignSelf: "stretch",
    backgroundColor: Colors.grayBackground,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    marginBottom: 50,
    paddingHorizontal: 30,
    justifyContent: "center",
  },
  moreButton: {
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    borderRadius: 20,
    padding: 5,
  },
  contactButton: {
    ...getClass("btn-border2"),
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
    width: 320,
  },
  contactButtonText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 20,
    color: getClass("btn-text-contact"),
    textAlign: "center",
    marginLeft: 5,
  },
});

export default VenueProfileView;
