import React, { useEffect, useRef, useState } from "react";
import { Colors, Constants, Fonts, Images, Values } from "../../../../config";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native-web";
import Link from "next/link";
import { NextSeo } from "next-seo";
import { useTranslation } from "react-i18next";
import ProfileHeader from "../../components/ProfileHeader";
import EventInfoView from "../components/EventInfoView";
import ProfileBottomView from "../../components/ProfileBottomView";
import { CImage, IconizedButton } from "../../../../components/atoms";
import ProfileModalContainer from "../../modals/ProfileModalContainer";
import { useRouter } from "next/router";
import { PopupMenuListModal } from "../../../../components/molecules";
import ProfileBottomMenuBar from "../../components/ProfileBottomMenuBar";
import {
  generateURLSlug,
  getCityCountryString,
  getArrayToString,
  getSlugFromName,
} from "../../../../../helpers";
import moment from "moment";
import Head from "next/head";
import { capitalizeFirstLetter } from "../../../../helpers/formatHelpers";
const PAGE_WIDTH = 1070;

const EventProfileView = (props) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const navigation = useRouter();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const { tabType, subTabType, isMobileVersion } = props;
  const [isReady, setIsReady] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [isShowMenuMagic, setIsShowMenuMagic] = useState(false);
  const itemsOther = isMobileVersion
    ? [
        {
          id: 12,
          title: t("key_report_mistake"),
          icon: Images.iconEdit,
          type: Constants.profileMenuType.PROFILE_MENU_REPORT_MISTAKE,
        },
        {
          id: 3,
          title: t("key_id_profile"),
          icon: Images.iconBarcode,
          type: Constants.profileMenuType.PROFILE_MENU_ID_PROFILE,
        },
        {
          id: 13,
          title: t("key_modal_title_share"),
          icon: Images.iconShareColored,
          type: Constants.profileMenuType.PROFILE_MENU_SHARE,
        },
      ]
    : [
        {
          id: 12,
          title: t("key_report_mistake"),
          icon: Images.iconEdit,
          type: Constants.profileMenuType.PROFILE_MENU_REPORT_MISTAKE,
        },
        {
          id: 3,
          title: t("key_id_profile"),
          icon: Images.iconBarcode,
          type: Constants.profileMenuType.PROFILE_MENU_ID_PROFILE,
        },
      ];
  const itemsMagic = [
    {
      id: 11,
      title: t("key_add_to_edition"),
      icon: Images.iconDateColored,
      type: Constants.profileMenuType.PROFILE_MENU_EDITION,
    },
    // {
    //   id: 7,
    //   title: t("key_insights"),
    //   icon: Images.iconInsight,
    //   type: Constants.profileMenuType.PROFILE_MENU_INSIGHTS,
    // },
  ];
  const onOpenExternalLink = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  };
  const onPressMenuItem = (item) => {
    if (item.type == Constants.profileMenuType.PROFILE_MENU_EDITION) {
      navigation.push(
        `${generateURLSlug(
          props?.event?.id,
          props?.event?.title,
          props?.event?.startDate,
          props.category,
          "edition"
        )}`
      );
    }
    if (item.type == Constants.profileMenuType.PROFILE_MENU_ID_PROFILE) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ID_PROFILE
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_REPORT) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_REPORT,
        {
          reportCategory: Constants.reportCategory.REPORT_FESTIVAL,
        }
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_UPDATE) {
      navigation.navigate("EventUpdateScreen", { eventId: props.event.id });
    } else if (
      item.type == Constants.profileMenuType.PROFILE_MENU_CONTACT_HOST
    ) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_CONTACT
      );
    } else if (
      item.type == Constants.profileMenuType.PROFILE_MENU_TICKET_LINK
    ) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ADD_TICKET_LINK
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_CALENDAR) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ADD_TO_CALENDAR
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_INSIGHTS) {
      navigation.push({
        pathname: "/profile/insight",
        query: {
          id: props.event.id,
          profileId: props.event.profileId,
        },
      });
    } else if (
      item.type == Constants.profileMenuType.PROFILE_MENU_REPORT_MISTAKE
    ) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_REPORT_MISTAKE
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_SHARE) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_SHARE
      );
    }
  };

  const onChangeIsShown = (flag) => {
    setIsShowMenu(flag);
  };
  let photos = props?.event?.photos;
  if (photos && !Array.isArray(photos)) {
    photos = JSON.parse(photos);
  }
  const backgroundImage =
    photos?.length > 0 ? photos[0].url : "Local:imagePlaceholder";

  useEffect(() => {
    setIsReady(true);
  }, []);

  var item = props?.event;
  const id = item?.id;
  const title = item?.title;
  const startDate = item?.startDate;
  const slug = getSlugFromName(title, startDate);
  const canonicalURL = `https://${
    process.env.NEXT_PUBLIC_WWW_BASE_URL
  }${generateURLSlug(
    props?.event?.id,
    props?.event?.title,
    props?.event.startDate,
    props.category,
    tabType === "about" ? "about" : `about#${tabType}`
  )}`;

  return (
    <View style={styles.container}>
      <Head>
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@graph": [
                {
                  "@context": "https://schema.org",
                  "@type": "Event",
                  "@id": canonicalURL,
                  url: canonicalURL,
                  name: props?.event?.title,
                  startDate: moment(props?.event?.startDate).format(
                    "YYYY-MM-DD"
                  ),
                  endDate: moment(props?.event?.endDate).format("YYYY-MM-DD"),
                  eventAttendanceMode: props?.event?.isVirtual
                    ? "https://schema.org/OnlineEventAttendanceMode"
                    : "https://schema.org/OfflineEventAttendanceMode",
                  eventStatus:
                    props?.event?.status ===
                      Constants.festivalStatus.FE_STAT_UPCOMMING_CONFIRMED ||
                    props?.event?.status ===
                      Constants.festivalStatus.FE_STAT_UPCOMMING_LIVE
                      ? "https://schema.org/EventScheduled"
                      : props?.event?.status ===
                        Constants.festivalStatus.FE_STAT_UPCOMMING_CANCELED
                      ? "https://schema.org/EventScheduled"
                      : Constants.festivalStatus
                          .FE_STAT_RESCHEDULE_TO_BE_UPDATED
                      ? "https://schema.org/EventRescheduled"
                      : Constants.festivalStatus.FE_STAT_UPCOMMING_TO_BE_UPDATED
                      ? "https://schema.org/ventPostponed"
                      : "https://schema.org/EventScheduled",
                  location: props?.event?.isVirtual
                    ? {
                        "@type": "VirtualLocation",
                        url: props?.event?.infoLinkUrl,
                      }
                    : {
                        "@type": "Place",
                        name:
                          props?.event?.venue ||
                          props?.event?.address ||
                          (props?.event?.city
                            ? `${props?.event?.city}, ${props?.event?.nationality}`
                            : props?.event?.nationality),

                        address: {
                          "@type": "PostalAddress",
                          streetAddress: props?.event?.address,
                          addressLocality: props?.event?.stateName,
                          addressRegion: props?.event?.stateCode,
                          addressCountry: props?.event?.countryCode,
                        },
                      },
                  image: [props?.event?.avatar]
                    .filter((i) => i !== null)
                    .map((i) => ({ "@id": i })),
                  description: props?.event?.description?.replace(
                    /(<([^>]+)>)/gi,
                    ""
                  ),
                  offers: {
                    "@type": "Offer",
                    url: `/${
                      props.category ===
                      Constants.categoryType.CATEGORY_FESTIVAL
                        ? "festivals"
                        : "events"
                    }/${getSlugFromName(
                      props?.event?.title,
                      props?.event?.startDate
                    )}/${props?.event?.id}/tickets`,
                    availability: "https://schema.org/InStock",
                  },
                  performer: props?.lineup?.map((item) => ({
                    "@type": "Person",
                    name: item.displayName,
                  })),
                },
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "@id": canonicalURL,
                  url: canonicalURL,
                  name: props?.event?.title,
                  datePublished: props?.event?.createdAt,
                  dateModified: props?.event?.updatedAt,
                  image: [props?.event?.avatar]
                    .filter((i) => i !== null)
                    .map((i) => ({ "@id": i })),
                  description: props?.event?.description?.replace(
                    /(<([^>]+)>)/gi,
                    ""
                  ),
                },
              ],
            }),
          }}
        />
      </Head>
      <NextSeo
        title={`${props?.event?.title} ${moment(props?.event?.startDate).format(
          "YYYY"
        )}${
          tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
        } - ${process.env.NEXT_PUBLIC_APP_NAME}`}
        description={`Discover ${props?.event?.title} ${moment(
          props?.event?.startDate
        ).format("YYYY")} ${
          tabType === "media"
            ? `media gallery on ${process.env.NEXT_PUBLIC_APP_NAME}.`
            : tabType === "wall"
            ? `community on ${process.env.NEXT_PUBLIC_APP_NAME} - posts about info, news and much more.`
            : tabType === "memories"
            ? `reviews on ${process.env.NEXT_PUBLIC_APP_NAME}.`
            : tabType === "lineup"
            ? `lineup on ${process.env.NEXT_PUBLIC_APP_NAME} - artists, poster and much more.`
            : `profile on ${process.env.NEXT_PUBLIC_APP_NAME} - dates, venue, tickets, lineup and much more.` //else about
        }`}
        canonical={canonicalURL}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: canonicalURL,
          title: `${props?.event?.title} ${moment(
            props?.event?.startDate
          ).format("YYYY")}${
            tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
          } - ${process.env.NEXT_PUBLIC_APP_NAME}`,
          description: `Discover ${props?.event?.title} ${moment(
            props?.event?.startDate
          ).format("YYYY")} ${
            tabType === "media"
              ? `media gallery on ${process.env.NEXT_PUBLIC_APP_NAME}.`
              : tabType === "wall"
              ? `community on ${process.env.NEXT_PUBLIC_APP_NAME} - posts about info, news and much more.`
              : tabType === "memories"
              ? `reviews on ${process.env.NEXT_PUBLIC_APP_NAME}.`
              : tabType === "lineup"
              ? `lineup on ${process.env.NEXT_PUBLIC_APP_NAME} - artists, poster and much more.`
              : `profile on ${process.env.NEXT_PUBLIC_APP_NAME} - dates, venue, tickets, lineup and much more.` //else about
          }`,
          images: [
            {
              url: props?.event?.avatar
                ? props?.event?.avatar
                : `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}/images/${
                    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                          " ",
                          ""
                        )?.toLowerCase() + "/"
                      : ""
                  }image_placeholder_new.png`,
              width: 1200,
              height: 630,
              alt: `${props?.event?.title} ${moment(
                props?.event?.startDate
              ).format("YYYY")}${
                tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
              } - ${process.env.NEXT_PUBLIC_APP_NAME}`,
              type: "image/png",
            },
          ],
          siteName: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
        }}
      />
      <ScrollView contentContainerStyle={styles.scrollView}>
        {isMobileVersion ? (
          <View style={{}}>
            {isReady && (
              <CImage
                objectFit={"fill"}
                imgUrl={backgroundImage}
                alt={`${props?.event?.title} ${moment(
                  props?.event?.startDate
                ).format("YYYY")} ${"Festival"}`}
              />
            )}
            <div
              className="bg-white/2 absolute bottom-0 left-0 right-0 top-0"
              style={{ backdropFilter: "blur(40px)" }}
            />

            <ProfileHeader
              photos={
                props?.event?.photos && props?.event?.photos?.length > 0
                  ? props?.event?.photos || []
                  : [{ url: "Local:imagePlaceholder" }]
              }
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              items={itemsOther}
              event={props.event}
              isShowMenu={isShowMenu}
              onChangeIsShown={onChangeIsShown}
              onPressMenuItem={onPressMenuItem}
              style={{
                maxWidth: Constants.PROFILE_PAGE_WIDTH,
                width: "100%",
                height: 239,
                marginBottom: 120,
                alignSelf: "center",
              }}
              isMobileVersion={isMobileVersion}
              category={props.category}
            />
          </View>
        ) : (
          <View
            style={{
              alignSelf: "stretch",
              height: 650,
            }}>
            {isReady && (
              <CImage
                objectFit={"fill"}
                imgUrl={backgroundImage}
                alt={`${props?.event?.title} ${moment(
                  props?.event?.startDate
                ).format("YYYY")} ${"Festival"}`}
              />
            )}
            <div
              className="bg-white/2 absolute bottom-0 left-0 right-0 top-0"
              style={{ backdropFilter: "blur(40px)" }}
            />

            <ProfileHeader
              photos={
                props?.event?.photos && props?.event?.photos?.length > 0
                  ? props?.event?.photos || []
                  : [{ url: "Local:imagePlaceholder" }]
              }
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              items={itemsOther}
              event={props.event}
              isShowMenu={isShowMenu}
              onChangeIsShown={onChangeIsShown}
              onPressMenuItem={onPressMenuItem}
              customNavBar={<div></div>}
              style={{
                maxWidth: Constants.PROFILE_PAGE_WIDTH,
                width: "100%",
                height: 530,
                marginBottom: 120,
                alignSelf: "center",
              }}
              isMobileVersion={isMobileVersion}
              category={props.category}
            />
          </View>
        )}

        {isMobileVersion ? (
          <EventInfoView
            style={{
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              marginTop: -120,
            }}
            item={props.event}
            loadData={props.loadData}
            isMobileVersion={isMobileVersion}
          />
        ) : (
          <EventInfoView
            style={{
              borderTopLeftRadius: 120,
              borderTopRightRadius: 120,
              marginTop: -120,
            }}
            item={props.event}
            loadData={props.loadData}
            isMobileVersion={isMobileVersion}
          />
        )}

        {isMobileVersion ? (
          <ProfileBottomView
            style={{
              // marginTop: -25,
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
              alignSelf: "center",
            }}
            item={props.event}
            category={props.category}
            isNested
            initialTabType={tabType}
            subTabType={subTabType}
            isMobileVersion={isMobileVersion}
          />
        ) : (
          <ProfileBottomView
            style={{
              marginTop: 20,
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
              alignSelf: "center",
            }}
            item={props.event}
            category={props.category}
            isNested
            initialTabType={tabType}
            subTabType={subTabType}
          />
        )}

        <ProfileModalContainer
          ref={modalRef}
          item={props.event}
          category={props.category}
          isMobileVersion={isMobileVersion}
        />
      </ScrollView>
      {isMobileVersion ? (
        <View
          style={[
            {
              height: isMobileVersion ? 62 : 80,
              marginTop: -30,
              justifyContent: "space-around",
              flexDirection: "row",
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
              alignSelf: "center",
              paddingHorizontal: 30,
              borderTopLeftRadius: isMobileVersion ? 25 : 40,
              borderTopRightRadius: isMobileVersion ? 25 : 40,
              alignItems: "center",
              backgroundColor: Colors.grayBackground,
              filter: "drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.5))",
              position: "fixed",
              bottom: 0,
            },
          ]}>
          <Link
            href={`/${
              props.category === Constants.categoryType.CATEGORY_FESTIVAL
                ? "festivals"
                : "events"
            }/${slug}/${id}/tickets`}>
            <a style={{ width: 23, height: 23 }}>
              <CImage
                source={Images.iconTicketWhite}
                width={23}
                height={23}
                layout="fixed"
              />
            </a>
          </Link>
          {props.category === Constants.categoryType.CATEGORY_FESTIVAL && (
            <TouchableOpacity onPress={() => setIsShowMenuMagic(true)}>
              <CImage
                source={Images.iconMenuMagic}
                width={50}
                height={50}
                layout="fixed"
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() =>
              modalRef.current.showModal(
                Constants.profileModalType.PROFILE_MODAL_CONTACT
              )
            }>
            <CImage
              source={Images.iconChat}
              width={23}
              height={23}
              layout="fixed"
            />
          </TouchableOpacity>
        </View>
      ) : (
        <ProfileBottomMenuBar
          profile={props.event}
          isMobileVersion={isMobileVersion}
          category={props.category}
          onMorePress={() => {
            setIsShowMenu(true);
          }}
          onContactPress={() =>
            modalRef.current.showModal(
              Constants.profileModalType.PROFILE_MODAL_CONTACT
            )
          }
          onSharePress={() =>
            modalRef.current.showModal(
              Constants.profileModalType.PROFILE_MODAL_SHARE
            )
          }
          renderMagicButton={() => {
            if (props.category === Constants.categoryType.CATEGORY_FESTIVAL) {
              return (
                <TouchableOpacity onPress={() => setIsShowMenuMagic(true)}>
                  <CImage
                    source={Images.iconMenuMagic}
                    width={60}
                    height={60}
                    layout="fixed"
                  />
                </TouchableOpacity>
              );
            } else {
              return null;
            }
          }}
          renderButton={() => {
            return (
              <View>
                <IconizedButton
                  isHeading
                  buttonTitle={t("key_button_ticket")}
                  icon={Images.iconTicket}
                  url={`/${
                    props.category === Constants.categoryType.CATEGORY_FESTIVAL
                      ? "festivals"
                      : "events"
                  }/${slug}/${id}/tickets`}
                  filled
                  fontSize={isMobileVersion ? 12 : 16}
                  iconStyle={{ width: 20, height: 20 }}
                  style={
                    isMobileVersion
                      ? { height: 32, width: "100%" }
                      : { height: 40, width: 320 }
                  }
                  isMobileVersion={isMobileVersion}
                />
              </View>
            );
          }}
        />
      )}
      <PopupMenuListModal
        isVisible={isShowMenuMagic}
        onClose={() => {
          setIsShowMenuMagic(false);
        }}
        onPressItem={onPressMenuItem}
        items={itemsMagic}
        isMobileVersion={isMobileVersion}
        category={props.category}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.black,
    marginBottom: 0,
  },
  bottomContainer: {
    height: 90,
    alignSelf: "stretch",
    backgroundColor: Colors.grayBackground,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    marginBottom: 50,
    paddingHorizontal: 30,
    justifyContent: "center",
  },
  scrollView: {
    flexGrow: 1,
    flexDirection: "column",
  },
  moreButton: {
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    borderRadius: 20,
    padding: 5,
  },
});

export default EventProfileView;
